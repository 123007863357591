const dev = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-dev-attachmentsbucket-6wbhcogxihbo"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://silescx2od.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_v2CHFR7Xm",
    APP_CLIENT_ID: "7n9jpr7fqn1d7nivrq7vklokue",
    IDENTITY_POOL_ID: "us-east-1:7b2ce76f-62fa-4fb5-9e59-2bb3970ea3d3"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-2-api-prod-attachmentsbucket-1h5n5ttet1hy0"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://silescx2od.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_v2CHFR7Xm",
    APP_CLIENT_ID: "7n9jpr7fqn1d7nivrq7vklokue",
    IDENTITY_POOL_ID: "us-east-1:7b2ce76f-62fa-4fb5-9e59-2bb3970ea3d3"
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
