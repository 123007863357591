//@ts-check

import React, { Component } from 'react'
import { API } from 'aws-amplify'

const MyContext = React.createContext({})

const reducer = (state, action) => {
    switch (action.type) {
        case 'HOME_PROCESSED':
            return {
                ...state,
                homeProcessed: action.payload,
            }
        case 'REGION_PROCESSED':
            return {
                ...state,
                regionProcessed: action.payload,
            }            
        case 'LOCALE_PROCESSED':
            return {
                ...state,
                localeProcessed: action.payload,
            }   
        case 'CHART_DATA':
            let chartData = state.chartData;
            return {
                ...state,
                chartData: {...chartData, [action.name]: action.payload},
            }                        
        default:
            return state
    }
}

export class Provider extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadData: this.retriveData,
            dataLoaded: false,
            isLoading: false,
            homeProcessed: false,
            regionProcessed: false,
            localeProcessed: false,
            filter: {
                metric: '', // metric: ['registration', 'transaction', 'referral', 'tasks', 'sms'],
                timeRange: '', // month or last week
                type: '', // count or value
            },
            products: {
                'com.freeramble.smsautosender.1': 0.99,
                'com.freeramble.smsautosender.2': 1.99,
                'com.freeramble.smsautosender.3': 4.99,
                'com.freeramble.smsautosender.4': 9.99,
            },
            metrics: ['registration', 'transaction', 'referral', 'tasks', 'sms'],
            scopes: ['TOTAL', 'REGION', 'LOCALE'],
            timeRange: ['YEAR', 'DATE', 'REGION', 'LOCALE'],
            data: {}, // will be filled with data with above data
            chartData: {},
            dispatch: action => this.setState(state => reducer(state, action)),
        }
        //this.retriveData = this.retriveData.bind(this);
    }

    processSummaryData(inData, metric, scope, timeRange, ys) {
        let d = [...inData[metric][scope][timeRange][0].data]

        let data = []
        let categories = []

        console.log('ProcessSummaryData', d)
        for (let i = 0; i < d.length; i++) {
            categories[i] = d[i].x
            data[i] = {}
            for (let j = 0; j < ys.length; j++) {
                let y = ys[j]
                data[i][y] = d[i][y]
                console.log('data', data)
            }
        }
        return {
            title: `${metric}`,
            name: timeRange,
            data,
            categories,
        }
    }
    fineTuneData(inData, metric, scope, timeRange, y) {
        let d = [...inData[metric][scope][timeRange][0].data]

        let data = []
        let categories = []
        if (timeRange !== 'DATE') {
            d = d.sort((a, b) => b[y] - a[y])
        }

        for (let i = 0; i < d.length; i++) {
            categories[i] = d[i].x
            data[i] = { x: d[i].x, y: d[i][y] }
        }
        return {
            title: `${metric} ${y} by ${timeRange}`,
            name: timeRange,
            data,
            categories,
        }
    }
    fineTuneData1(inData, metric, scope, timeRange, y) {
        let dd = [...inData[metric][scope][timeRange]]
        let series = []
        
        let categories = []
        for (let j = 0; j < dd.length; j++) {
            let serie = {}
            let ddd = dd[j]
            let d = [...ddd.data];
            let data = []

            serie.name = ddd.name;
            for (let i = 0; i < d.length; i++) {
                categories[i] = d[i].x
                data[i] = { x: d[i].x, y: d[i][y] }
            }
            serie.data = data;
            series.push(serie);
        }
        console.log(series);
        return {
            title: `${metric} ${y} by ${timeRange}`,
            name: timeRange,
            series,
            categories,
        }
    }

    retriveData = async () => {
        this.setState( { dataLoaded: false })
        this.setState( { isLoading: true })
        await this.loadData()
        //await this.getSeriesData()
        this.setState({ dataLoaded: true })
        this.setState( { isLoading: false })
    }
    async componentDidMount() {
      // this.retriveData()
        // this.setState({ dataLoaded: false })
        // await this.loadData()
        // //await this.getSeriesData()
        // this.setState({ dataLoaded: true })
    }

    async getSeriesData() {
        const metric = 'registration'
        const range = 'DATE'
        const result = await this.getData(metric, 'REGION', range)
        const r = this.processSeriesData(result.data)
        console.log('Series data processed:', r)
        //return result;
        console.log('Series Data', result)
    }

    processSeriesData = d => {
        console.log('insde processSeries data:', d)
        if (d && d.length === 0) {
            return null
        }
        let date = d.map(m => {
            return m.date.substring(
                m.date.indexOf('#') + 1,
                m.date.lastIndexOf('#')
            )
        })

        let series = d.map(m => {
            return m.date.substring(m.date.lastIndexOf('#') + 1, m.date.length)
        })

        date = [...new Set(date)]
        series = [...new Set(series)]

        console.log(date, series)
        let result = []

        series.forEach(s => {
            let serie = {}
            let dd = []

            if (s === '') {
                serie.name = 'TOTAL'
            } else {
                serie.name = s
            }

            date.forEach(c => {
                //iterate each date
                let data = {}
                let ff = d
                    .filter(
                        //match the date
                        f =>
                            f.date.substring(
                                f.date.indexOf('#') + 1,
                                f.date.lastIndexOf('#')
                            ) === c
                    )
                    .filter(
                        //match the series (REGION/LOCALE)
                        m =>
                            m.date.substring(
                                m.date.lastIndexOf('#') + 1,
                                m.date.length
                            ) === s
                    )

                if (ff.length === 1) {
                    let keys = Object.keys(ff[0].KPI)
                    let value = 0
                    let count = 0
                    keys.map(m => {
                        value += ff[0].KPI[m].value
                        count += ff[0].KPI[m].count
                    })
                    data = {
                        x: c,
                        count: count,
                        value: value,
                        kpi: ff[0].KPI,
                    }
                } else {
                    data = {
                        x: c,
                        count: null,
                        value: null,
                        kpi: null,
                    }
                }
                dd.push(data)
            })
            serie.data = dd
            console.log('serie', serie)
            result.push(serie)
        })
        return result
    }

    loadData = async () => {
        let metrics = this.state.metrics
        let scopes = this.state.scopes
        let timeRange = this.state.timeRange

        let data ={}
        for (let j = 0; j < metrics.length; j++) {
            let metric = metrics[j]
            data[metric] = {}

            for (let k = 0; k < scopes.length; k++) {
                let scope = scopes[k]
                let ps = timeRange.map(range => {
                    return this.getData(metric, scope, range)
                })
                const results = await Promise.all(ps)

                data[metric][scope] = {}

                for (let i = 0; i < results.length; i++) {
                    const d = this.processTimeData(results[i].data)
                    data[metric][scope][timeRange[i]] = d
                    
                }
            }
        }
        this.setState({ data })
        console.log('Data loaded: ', data)
    }

    getData = (metric, scope, timeRange) => {
        const options = {
            body: {
                metric: metric,
                scope: scope,
                timeRange: timeRange,
            },
        }
        return API.post('autosender', '/data', options)
        //return data.data
    }

    processTimeData = d => {
        if (d && d.length === 0) {
            return null
        }
        let date = d.map(m => {
            return m.date.substring(
                m.date.indexOf('#') + 1,
                m.date.lastIndexOf('#')
            )
        })

        let series = d.map(m => {
            return m.date.substring(m.date.lastIndexOf('#') + 1, m.date.length)
        })

        date = [...new Set(date)]
        series = [...new Set(series)]

        let result = []

        series.forEach(s => {
            let serie = {}
            let dd = []

            if (s === '') {
                serie.name = 'TOTAL'
            } else {
                serie.name = s
            }

            date.forEach(c => {
                //iterate each date
                let data = {}
                let ff = d
                    .filter(
                        //match the date
                        f =>
                            f.date.substring(
                                f.date.indexOf('#') + 1,
                                f.date.lastIndexOf('#')
                            ) === c
                    )
                    .filter(
                        //match the series (REGION/LOCALE)
                        m =>
                            m.date.substring(
                                m.date.lastIndexOf('#') + 1,
                                m.date.length
                            ) === s
                    )

                if (ff.length === 1) {
                    let keys = Object.keys(ff[0].KPI)
                    let value = 0
                    let count = 0
                    let sales = 0
                    keys.map(m => {
                        if (this.state.products.hasOwnProperty(m)) {
                            sales =
                                sales +
                                ff[0].KPI[m].count * this.state.products[m]
                        }
                        value += ff[0].KPI[m].value
                        count += ff[0].KPI[m].count
                    })
                    data = {
                        x: c,
                        count: count,
                        value: value,
                        sales: sales,
                        kpi: ff[0].KPI,
                    }
                    // if (ff[0].dimension.includes('transaction')) {
                    //     data.sales = 0
                    // }
                } else {
                    data = {
                        x: c,
                        count: null,
                        value: null,
                        sales: null,
                        kpi: null,
                    }
                }
                dd.push(data)
            })
            serie.data = dd
            //console.log('serie', serie)
            result.push(serie)
        })
        return result

    }

    render() {
        return (
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        )
    }
}

export const Consumer = MyContext.Consumer

export const Context = MyContext