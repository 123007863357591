//@ts-check
import React, { Component } from 'react'
import { API } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import {
    PageHeader,
    ListGroup,
    Tooltip,
    OverlayTrigger,
    Button,
    Grid,
    Row,
    Col,
    Well,
    Label,
} from 'react-bootstrap'
import Chart from 'react-apexcharts'
import { Consumer } from '../MyContext'
import LoaderButton from '../components/LoaderButton'

export default class Locale extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            layout: {
                region: {
                    title: 'Data by Region',
                    type: 'chart',
                    y: ['count', 'count', 'count', 'count', 'count'],
                    chartType: ['line', 'line', 'line', 'line', 'line'],
                    metric: ['registration', 'transaction', 'referral', 'tasks', 'sms'],
                    scope: ['LOCALE', 'LOCALE', 'LOCALE', 'LOCALE', 'LOCALE'],
                    timeRange: ['DATE', 'DATE', 'DATE', 'DATE', 'DATE'],
                    chart: {
                        type: 'line',
                        series: [],
                        options: {
                            xaxis: {},
                            chart: {
                                group: 'autosender#registration',
                            },
                            title: {
                                text: '',
                                offsetX: 30,
                                style: {
                                    fontSize: '24px',
                                },
                            },
                            subtitle: {
                                text: 'registration',
                                offsetX: 30,
                                style: {
                                    fontSize: '14px',
                                },
                            },
                        },
                    },
                }
            },
            chartOptions: {
                bar: {
                    xaxis: {},
                    chart: {
                        type: 'bar',
                        group: 'autosender',
                        sparkline: {
                            enabled: true,
                        },
                    },
                    title: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '24px',
                        },
                    },
                    subtitle: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '14px',
                        },
                    },
                    tooltip: {
                        enabled: true,
                        x: {
                            show: false,
                            format: 'dd MMM',
                            formatter: undefined,
                        },
                    },
                },
                line: {
                    xaxis: {
                        type: 'datetime',
                    },
                    chart: {
                        type: 'line',
                        id: 'chart-area',
                        group: 'autosender-line',
                    },
                    title: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '24px',
                        },
                    },
                    subtitle: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '14px',
                        },
                    },
                    tooltip: {
                        followCursor: false,
                        theme: 'dark',
                        x: {
                            show: false,
                        },
                        marker: {
                            show: false,
                        },
                        y: {
                            title: {
                                formatter: function() {
                                    return ''
                                },
                            },
                        },
                    },
                },
                column: {
                    xaxis: {},
                    chart: {
                        type: 'bar',
                        group: 'autosender',
                        sparkline: {
                            enabled: true,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        },
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    title: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '24px',
                        },
                    },
                    subtitle: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '14px',
                        },
                    },
                    tooltip: {
                        enabled: true,
                        x: {
                            show: false,
                            format: 'dd MMM',
                            formatter: undefined,
                        },
                    },
                },
            },
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return
        }

        this.setState({ isLoading: false })
    }

    
    renderChart(data, processData, chart) {
        const {
            title,
            chartType,
            metric,
            scope,
            timeRange,
            y,
        } = chart

        let values = []
        for (let i = 0; i < metric.length; i++) {
            let value = processData(
                data,
                metric[i],
                scope[i],
                timeRange[i],
                y[i]
            )
            value.type = chartType[i]
            values.push(value)
        }
        console.log('renderChart values:', values)

        const listChart = values.map(value => {
            let options = this.state.chartOptions[value.type]
            options.xaxis.categories = value.categories
            let series = value.series;
            // if (value.name !== 'DATE') {
            //     for (let i = 0; i < value.data.length; i++) {
            //         let d = value.data[i]
            //         let dd = []
            //         dd.push(d.y)
            //         series.push({ name: d.x, data: dd })
            //         if (i > 8) {
            //             break
            //         }
            //     }
            // } else {
//                series = [{ name: value.name, data: value.data }]
            // }

            console.log('series:', series)

            let type = value.type
            if (value.type === 'column') {
                type = 'bar'
            }
            return (
                <Row key={value.title}>
                    <h3>{value.title}</h3>
                    <Chart options={options} series={series} type={type} />
                </Row>
            )
        })
        return (
            <Well key={title} className="show-grid mt-4 mb-4">
                {listChart}
            </Well>
        )
    }
    renderLander() {
        return (
            <div className="lander">
                <h1>AutoSender</h1>
                <p>AutoSender Management Analytics System</p>
                <div>
                    <Link to="/login" className="btn btn-info btn-lg">
                        Login
                    </Link>
                    <Link to="/signup" className="btn btn-success btn-lg">
                        Signup
                    </Link>
                </div>
            </div>
        )
    }

    renderData() {
        return ( <div></div>
            // <Consumer>
            //     {value => {
            //         //console.log('value::', value)
            //         const {
            //             data,
            //             dataLoaded,
            //             processData,
            //             isLoading,
            //             loadData,
            //         } = value
            //         if (!dataLoaded) {
            //             return (
            //                 <div className="lander">
            //                     <Well>
            //                         <form onSubmit={this.handleSubmit}>
            //                             <LoaderButton
            //                                 block
            //                                 bsSize="large"
            //                                 bsStyle="success"
            //                                 type="submit"
            //                                 isLoading={isLoading}
            //                                 text="Load Data"
            //                                 loadingText="Data loading…"
            //                             />
            //                         </form>
            //                     </Well>
            //                 </div>
            //             )
            //         }
            //         const allCharts = Object.keys(this.state.layout) //get all charts from layout

            //         //console.log('allCharts', allCharts)
            //         return (
            //             //<div className="autosender">
            //             <Grid className="autosender_Grid">
            //                 {allCharts.map(chart => {
            //                         return this.renderChart(data, processData, this.state.layout[chart])
            //                 })}
            //             </Grid>
            //             //</div>
            //         )
            //     }}
            // </Consumer>
        )
    }

    render() {
        return (
            <div className="Locale">
                {this.props.isAuthenticated
                    ? this.renderData()
                    : this.renderLander()}
            </div>
        )
    }
}
