import React, { Component } from 'react'
import { Consumer } from '../MyContext'
import { FormGroup, ControlLabel, FormControl} from 'react-bootstrap'
class Filter extends Component {
    state = {
        scope: '',
        timeRange: '',
    }

    filterData = (dispatch, e) => {
        e.preventDefault()

        dispatch({
            type: 'SEARCH_TRACKS',
            payload: "",
        })

        this.setState({ scope: '' })
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        return (
            <Consumer>
                {value => {
                    const { dispatch } = value
                    return (
                        <div className="card card-body mb-4 p-4">
                            <h1 className="display-4 text-center">
                                <i className="fas fa-music" /> Filter Your Data
                            </h1>
                            <p className="lead text-center">Filter</p>
                            <form
                                onSubmit={this.filterData.bind(this, dispatch)}
                            >
                                <FormGroup controlId="formControlsSelect">
                                    <ControlLabel>Select</ControlLabel>
                                    <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                    >
                                        <option value="TOTAL">TOTAL</option>
                                        <option value="REGION">REGION</option>
                                        <option value="LOCALE">LOCALE</option>
                                    </FormControl>
                                </FormGroup>
                                <button
                                    className="btn btn-primary btn-lg btn-block mb-5"
                                    type="submit"
                                >
                                    Update Data
                                </button>
                            </form>
                        </div>
                    )
                }}
            </Consumer>
        )
    }
}

export default Filter
