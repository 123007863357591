//@ts-check
import React, { Component } from 'react'
import { API } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import {
    PageHeader,
    ListGroup,
    Tooltip,
    OverlayTrigger,
    Button,
    Grid,
    Row,
    Col,
    Well,
    Label,
} from 'react-bootstrap'
import './Home.css'
import MyChart from '../components/Chart'
import Filter from '../components/Filter'
import Chart from 'react-apexcharts'
import LoaderButton from '../components/LoaderButton'
import Region from './Region'

const processDate = () => {
    const date = new Date()

    const split = {
        Date:
            date.getUTCDate() >= 10
                ? date.getUTCDate()
                : '0' + date.getUTCDate(),
        Hour:
            date.getUTCHours() >= 10
                ? date.getUTCHours()
                : '0' + date.getUTCHours(),
        Minute:
            date.getUTCMinutes() >= 10
                ? date.getUTCMinutes()
                : '0' + date.getUTCMinutes(),
        Month:
            date.getUTCMonth() >= 9
                ? date.getUTCMonth() + 1
                : '0' + (date.getUTCMonth() + 1),
        Year: date.getUTCFullYear(),
    }
    return split
}

export default class Home extends Component {
    constructor(props) {
        super(props)

        console.log('const', props)
        this.state = {
            dataLoaded: false,
            chartData: {},
            layout: {
                compare: {
                    title: 'Compare',
                    type: 'text',
                    y: [
                        ['count', 'count'],
                        ['count', 'count'],
                        ['count', 'count'],
                        ['count', 'count'],
                        ['count', 'count'],
                    ],
                    yLabel: [
                        ['Yesterday', 'Today'],
                        ['Yesterday', 'Today'],
                        ['Yesterday', 'Today'],
                        ['Yesterday', 'Today'],
                        ['Yesterday', 'Today'],
                    ],
                    chartType: ['text', 'text', 'text', 'text', 'text'],
                    scope: ['TOTAL', 'TOTAL', 'TOTAL', 'TOTAL', 'TOTAL'],
                    timeRange: ['DATE', 'DATE', 'DATE', 'DATE', 'DATE'],
                    metric: [
                        'registration',
                        'transaction',
                        'referral',
                        'tasks',
                        'sms',
                    ],
                    process: this.processCompareData,
                },
                summary: {
                    title: 'Summary',
                    type: 'text',
                    y: [
                        ['count', 'value'],
                        ['count', 'value', 'sales'],
                        ['count', 'value'],
                        ['count', 'value'],
                        ['count', 'value'],
                    ],
                    yLabel: [
                        ['Total', 'Initial Credits'],
                        ['Total', 'Purchased Credits', 'USD'],
                        ['Total', 'Referral Credits'],
                        ['Total', 'Task Credits'],
                        ['Total', 'Consumed Credits'],
                    ],
                    chartType: ['text', 'text', 'text', 'text', 'text'],
                    scope: ['TOTAL', 'TOTAL', 'TOTAL', 'TOTAL', 'TOTAL'],
                    timeRange: ['YEAR', 'YEAR', 'YEAR', 'YEAR', 'YEAR'],
                    metric: [
                        'registration',
                        'transaction',
                        'referral',
                        'tasks',
                        'sms',
                    ],
                    process: this.processSummaryData,
                },
                registration: {
                    process: this.processData,
                    title: 'registration count',
                    type: 'chart',
                    y: ['count', 'count', 'count'],
                    chartType: ['bar', 'column', 'area'],
                    metric: ['registration', 'registration', 'registration'],
                    scope: ['TOTAL', 'TOTAL', 'TOTAL'],
                    timeRange: ['REGION', 'LOCALE', 'DATE'],
                    chart: {
                        type: 'line',
                        series: [],
                        options: {
                            xaxis: {},
                            chart: {
                                group: 'autosender#registration',
                                sparkline: {
                                    enabled: true,
                                },
                            },
                            marker: {
                                show: false,
                            },
                            stroke: {
                                curve: 'straight',
                            },
                            title: {
                                text: '',
                                offsetX: 30,
                                style: {
                                    fontSize: '24px',
                                },
                            },
                            subtitle: {
                                text: 'registration',
                                offsetX: 30,
                                style: {
                                    fontSize: '14px',
                                },
                            },
                        },
                    },
                },
                transaction: {
                    process: this.processData,
                    title: 'transaction by value',
                    type: 'chart',
                    y: ['count', 'count', 'count'],
                    chartType: ['bar', 'column', 'area'],
                    metric: ['transaction', 'transaction', 'transaction'],
                    scope: ['TOTAL', 'TOTAL', 'TOTAL'],
                    timeRange: ['REGION', 'LOCALE', 'DATE'],
                    chart: {
                        type: 'line',
                        options: {
                            xaxis: {},
                            chart: {
                                group: 'autosender',
                                sparkline: {
                                    enabled: true,
                                },
                            },
                            marker: {
                                show: false,
                            },
                            stroke: {
                                width: 2,
                            },
                            title: {
                                text: '',
                                offsetX: 30,
                                style: {
                                    fontSize: '24px',
                                },
                            },
                            subtitle: {
                                text: 'transaction',
                                offsetX: 30,
                                style: {
                                    fontSize: '14px',
                                },
                            },
                        },
                        series: [],
                    },
                    data: [],
                },
                referral: {
                    process: this.processData,
                    title: 'referral by value',
                    type: 'chart',
                    y: ['count', 'count', 'count'],
                    chartType: ['bar', 'column', 'area'],
                    metric: ['referral', 'referral', 'referral'],
                    scope: ['TOTAL', 'TOTAL', 'TOTAL'],
                    timeRange: ['REGION', 'LOCALE', 'DATE'],
                    chart: {
                        type: 'line',
                        options: {
                            xaxis: {},
                            chart: {
                                group: 'autosender',
                                sparkline: {
                                    enabled: true,
                                },
                            },
                            marker: {
                                show: false,
                            },
                            stroke: {
                                width: 2,
                            },
                            title: {
                                text: '',
                                offsetX: 30,
                                style: {
                                    fontSize: '24px',
                                },
                            },
                            subtitle: {
                                text: 'referral',
                                offsetX: 30,
                                style: {
                                    fontSize: '14px',
                                },
                            },
                        },
                        series: [],
                    },
                    data: [],
                },
                tasks: {
                    process: this.processData,
                    title: 'tasks by count',
                    type: 'chart',
                    y: ['count', 'count', 'count'],
                    chartType: ['bar', 'column', 'area'],
                    metric: ['tasks', 'tasks', 'tasks'],
                    scope: ['TOTAL', 'TOTAL', 'TOTAL'],
                    timeRange: ['REGION', 'LOCALE', 'DATE'],
                    chart: {
                        type: 'line',
                        options: {
                            xaxis: {},
                            chart: {
                                group: 'autosender',
                                sparkline: {
                                    enabled: true,
                                },
                            },
                            marker: {
                                show: false,
                            },
                            stroke: {
                                width: 2,
                            },
                            title: {
                                text: '',
                                offsetX: 30,
                                style: {
                                    fontSize: '24px',
                                },
                            },
                            subtitle: {
                                text: 'tasks',
                                offsetX: 30,
                                style: {
                                    fontSize: '14px',
                                },
                            },
                        },
                        series: [],
                    },
                    data: [],
                },
                sms: {
                    process: this.processData,
                    title: 'sms by count',
                    type: 'chart',
                    y: ['count', 'count', 'count'],
                    chartType: ['bar', 'column', 'area'],
                    metric: ['sms', 'sms', 'sms'],
                    scope: ['TOTAL', 'TOTAL', 'TOTAL'],
                    timeRange: ['REGION', 'LOCALE', 'DATE'],
                    chart: {
                        type: 'line',
                        options: {
                            xaxis: {},
                            chart: {
                                group: 'autosender',
                                sparkline: {
                                    enabled: true,
                                },
                            },
                            marker: {
                                show: false,
                            },
                            stroke: {
                                width: 2,
                            },
                            title: {
                                text: 'sms',
                            },
                        },
                        series: [],
                    },
                    data: [],
                },
            },
            chartOptions: {
                bar: {
                    xaxis: {},
                    chart: {
                        type: 'bar',
                        sparkline: {
                            enabled: true,
                        },
                    },
                    title: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '24px',
                        },
                    },
                    subtitle: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '14px',
                        },
                    },
                    tooltip: {
                        enabled: true,
                        x: {
                            show: false,
                            format: 'dd MMM',
                            formatter: undefined,
                        },
                    },
                },
                area: {
                    xaxis: {
                        type: 'datetime',
                    },
                    chart: {
                        type: 'area',
                        id: 'chart-area',
                        group: 'autosender-area-1',
                        sparkline: {
                            enabled: true,
                        },
                    },
                    marker: {
                        show: false,
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    title: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '24px',
                        },
                    },
                    subtitle: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '14px',
                        },
                    },
                    tooltip: {
                        followCursor: false,
                        theme: 'dark',
                        x: {
                            show: true,
                        },
                        marker: {
                            show: false,
                        },
                        y: {
                            title: {
                                formatter: function() {
                                    return ''
                                },
                            },
                        },
                    },
                },
                column: {
                    xaxis: {},
                    chart: {
                        type: 'bar',
                        sparkline: {
                            enabled: true,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        },
                    },
                    stroke: {
                        curve: 'straight',
                    },
                    title: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '24px',
                        },
                    },
                    subtitle: {
                        text: '',
                        offsetX: 30,
                        style: {
                            fontSize: '14px',
                        },
                    },
                    tooltip: {
                        enabled: true,
                        x: {
                            show: false,
                            format: 'dd MMM',
                            formatter: undefined,
                        },
                    },
                },
            },
        }
    }

    handleSubmit = async event => {
        event.preventDefault()
        try {
            console.log('handle submit', this.props)
            await this.props.chart.loadData()
            this.loadData()
            this.setState({ dataLoaded: true })
        } catch (e) {
            alert(e)
        }
    }

    handleRefresh = async event => {
        event.preventDefault()
        try {
            console.log('handle submit', this.props)
            await this.props.chart.refreshData()
            this.loadData()
            this.setState({ dataLoaded: true })
        } catch (e) {
            alert(e)
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return
        }
        console.log('DidMount', this.props)
        if (this.props.dataLoaded) {
            this.loadData()
            this.setState({ dataLoaded: true })
        }
    }
    async componentDidUpdate() {
        console.log('DidUpdate', this.props)
    }
    async componentWillReceiveProps(nextProps) {
        console.log('WillReceive', this.props)
    }
    loadData() {
        const allCharts = Object.keys(this.state.layout) //get all charts from layout
        let data = this.props.chart.data
        let chartData = {}
        allCharts.map(chart => {
            let {
                process,
                metric,
                scope,
                timeRange,
                y,
                chartType,
            } = this.state.layout[chart]
            let values = []
            for (let i = 0; i < metric.length; i++) {
                let value = process(
                    data,
                    metric[i],
                    scope[i],
                    timeRange[i],
                    y[i]
                )
                value.type = chartType[i]
                values.push(value)
            }
            chartData[chart] = values
            console.log('renderText values:', values)
        })
        console.log('chartData:', chartData)

        this.setState({ chartData })
    }

    processData(inData, metric, scope, timeRange, y) {
        let d = [...inData[metric][scope][timeRange][0].data]

        let data = []
        let categories = []
        if (timeRange !== 'DATE') {
            d = d.sort((a, b) => b[y] - a[y])
        }

        for (let i = 0; i < d.length; i++) {
            categories[i] = d[i].x
            data[i] = { x: d[i].x, y: d[i][y] }
        }
        return {
            title: `${metric} ${y} by ${timeRange}`,
            name: timeRange,
            data,
            categories,
        }
    }

    processCompareData(inData, metric, scope, timeRange, y) {
        let d = [...inData[metric][scope][timeRange][0].data]

        let categories = []
        const data = []
        data[0] = {}

        const tempDate = processDate()
        const utcYear = tempDate.Year
        const utcMonth = tempDate.Month
        const utcDate = tempDate.Date

        const today = `${utcYear}-${utcMonth}-${utcDate}`

        let j = 0
        console.log('Compare D', d)
        const last = d[d.length - 1].x

        console.log('today and last', today, last)
        if (today === last) {
            for (let i = d.length - 2; i < d.length; i++) {
                categories[j] = d[i].x
                data[0][d[i].x] = d[i].count
                j++
            }
        } else {
            data[0][last] = d[d.length - 1].count
            data[0][today] = 0
        }

        console.log('Compare Data:', categories, data)
        return {
            title: `${metric}`,
            name: timeRange,
            data,
            categories,
        }
    }
    processSummaryData(inData, metric, scope, timeRange, ys) {
        let d = [...inData[metric][scope][timeRange][0].data]

        let data = []
        let categories = []

        console.log('ProcessSummaryData', d)
        for (let i = 0; i < d.length; i++) {
            categories[i] = d[i].x
            data[i] = {}
            for (let j = 0; j < ys.length; j++) {
                let y = ys[j]
                data[i][y] = d[i][y]
                if (i > 0) data[0][y] = d[i][y] + data[0][y]
                console.log('data', data)
            }
        }
        return {
            title: `${metric}`,
            name: timeRange,
            data,
            categories,
        }
    }

    renderText(chart) {
        let values = this.state.chartData[chart]
        let title = this.state.layout[chart]

        console.log('renderText', values, title)
        const listText = values.map(
            (value, i) =>
                i !== 0 ? (
                    <Col key={`${title}-col1-${i}`} xs={12} md={2}>
                        <h3> {value.title} </h3>
                        {Object.keys(value.data[0]).map((key, index) => (
                            <h4 key={key}>
                                {title.yLabel[i][index]}:{' '}
                                <Button bsStyle="success">
                                    {parseInt(value.data[0][key])}
                                </Button>
                            </h4>
                        ))}
                    </Col>
                ) : (
                    <Col
                        key={`${title}-cole2-${i}`}
                        xs={12}
                        md={2}
                        xsOffset={1}
                    >
                        <h3>{value.title}</h3>
                        {Object.keys(value.data[0]).map((key, index) => (
                            <h4 key={key}>
                                {title.yLabel[i][index]}:{' '}
                                <Button bsStyle="success">
                                    {value.data[0][key]}
                                </Button>
                            </h4>
                        ))}
                    </Col>
                )
        )
        return (
            <Well key={`${title.title}-well`}>
                <Row className="show-grid mt-6 mb-6 offset={1}">{listText}</Row>
            </Well>
        )
    }

    renderChart(chart) {
        let values = this.state.chartData[chart]
        let title = this.state.layout[chart].title

        if (!values) {
            console.log('Home no Values, yet, just return')
            return null
        }
        const listChart = values.map(value => {
            let options = this.state.chartOptions[value.type]
            options.xaxis.categories = value.categories
            let series = []
            if (value.name !== 'DATE') {
                for (let i = 0; i < value.data.length; i++) {
                    let d = value.data[i]
                    let dd = []
                    dd.push(d.y)
                    series.push({ name: d.x, data: dd })
                    if (i > 8) {
                        break
                    }
                }
            } else {
                series = [{ name: value.name, data: value.data }]
            }

            console.log('series:', series)

            let type = value.type
            if (value.type === 'column') {
                type = 'bar'
            }
            return (
                <Col key={value.title} xs={12} md={4}>
                    <h4>{value.title}</h4>
                    <Chart options={options} series={series} type={type} />
                </Col>
            )
        })
        return (
            <Well key={title}>
                <Row className="show-grid mt-8 mb-8">{listChart}</Row>
            </Well>
        )
    }
    renderLander() {
        return (
            <div className="lander">
                <h1>AutoSender</h1>
                <p>AutoSender Management Analytics System</p>
                <div>
                    <Link to="/login" className="btn btn-info btn-lg">
                        Login
                    </Link>
                    <Link to="/signup" className="btn btn-success btn-lg">
                        Signup
                    </Link>
                </div>
            </div>
        )
    }

    renderHome() {
        const t = processDate()
        const updateTime = `Refresh Data | Last update: ${t.Year}-${t.Month}-${
            t.Date
        } ${t.Hour}:${t.Minute} (UTC)`
        if (!this.state.dataLoaded) {
            return (
                <div className="lander">
                    <Well>
                        <form onSubmit={this.handleSubmit}>
                            <LoaderButton
                                block
                                bsSize="large"
                                bsStyle="success"
                                type="submit"
                                isLoading={this.props.isLoading}
                                text="Load Data"
                                loadingText="Data loading…"
                            />
                        </form>
                    </Well>
                </div>
            )
        }
        const allCharts = Object.keys(this.state.layout) //get all charts from layout

        return (
            <Grid className="autosender_Home">
                <Well key={'refresh_button'}>
                    <form onSubmit={this.handleRefresh}>
                        <LoaderButton
                            block
                            bsSize="large"
                            bsStyle="success"
                            type="submit"
                            isLoading={this.props.isLoading}
                            text={updateTime}
                            loadingText="Data loading…"
                        />
                    </form>
                </Well>
                {allCharts.map(chart => {
                    if (this.state.layout[chart].type === 'text') {
                        return this.renderText(chart)
                    } else {
                        return this.renderChart(chart)
                    }
                })}
            </Grid>
        )
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated
                    ? this.renderHome()
                    : this.renderLander()}
            </div>
        )
    }
}
