//@ts-check
import React, { Component } from 'react'
import { API } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import {
    PageHeader,
    ListGroup,
    Tooltip,
    OverlayTrigger,
    Button,
    Grid,
    Row,
    Col,
    Well,
    Label,
} from 'react-bootstrap'
import Chart from 'react-apexcharts'

import LoaderButton from '../components/LoaderButton'

export default class Region extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            dataLoaded: false,
            myChart: {},
            chartID: [],
            layout: {
                region: {
                    title: 'Data by Region',
                    type: 'chart',
                    y: ['count', 'count', 'count', 'count', 'count'],
                    chartType: ['area', 'area', 'area', 'area', 'area'],
                    metric: [
                        'registration',
                        'transaction',
                        'referral',
                        'tasks',
                        'sms',
                    ],
                    scope: ['REGION', 'REGION', 'REGION', 'REGION', 'REGION'],
                    timeRange: ['DATE', 'DATE', 'DATE', 'DATE', 'DATE'],
                },
            },
            chartOptions: {
                subLine: {
                    chart: {
                        id: `subChart`,
                        brush: {
                            target: '',
                            enabled: true,
                        },
                        animations: {
                            enabled: false,
                        },
                        selection: {
                            xaxis: {
                                min: this.getLastWeek().getTime(),
                                max: new Date().getTime(),
                            },
                        },
                    },
                    legend: {
                        show: false,
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: [],
                    },
                    yaxis: {
                        tickAmount: 2,
                    },
                },
                line: {
                    xaxis: {
                        type: 'datetime',
                        categories: [],
                    },
                    legend: {
                        position: 'right',
                    },
                    chart: {
                        type: 'line',
                        id: '',
                        animations: {
                            enabled: false,
                        },
                        toolbar: {
                            autoSelected: 'pan',
                            show: false,
                        },
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                },
            },
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return
        }
        if (this.props.dataLoaded) {
            this.initData()
            this.setState({ dataLoaded: true })
        }
    }
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true })
        // You can also log the error to an error reporting service
        console.log('Error:', error, info)
    }

    componentWillReceiveProps(newPros) {
        console.log('nextProps', newPros)
        if (newPros.dataLoaded) {
            this.initData()
            this.setState({ dataLoaded: true })
        }
        // console.log('componentWillUnmount')
        // let chartID = this.state.chartID
        // chartID.forEach(ch => {
        //     console.log(`Destroying ${ch}`)
        //     Apexcharts.exec(ch, 'destroy')
        // })
    }

    initData() {
        const allCharts = Object.keys(this.state.layout) //get all charts from layout
        let chartData = this.loadData()
        let myChart = {}
        let chartID = []

        allCharts.forEach(chart => {
            let values = chartData[chart]

            myChart[chart] = {}

            values.forEach((value, index) => {
                let title = value.title
                let options = {
                    xaxis: {
                        type: 'datetime',
                        categories: [...value.categories],
                    },
                    legend: {
                        position: 'right',
                    },
                    chart: {
                        type: 'area',
                        id: `charts-${index}`,
                        animations: {
                            enabled: false,
                        },
                        toolbar: {
                            autoSelected: 'pan',
                            show: false,
                        },
                    },
                    stroke: {
                        curve: 'smooth',
                    },
                }
                let series = [...value.series]
                console.log('series:', series)
                let sub_options = {
                    chart: {
                        id: `${value.title}-subChart`,
                        brush: {
                            target: `charts-${index}`,
                            enabled: true,
                        },
                        animations: {
                            enabled: false,
                        },
                        selection: {
                            enabled: true,
                            xaxis: {
                                min: this.getLastWeek().getTime(),
                                max: new Date().getTime(),
                            },
                        },
                    },
                    brush: {
                        target: `charts-${index}`,
                        enabled: true,
                    },
                    animations: {
                        enabled: false,
                    },
                    selection: {
                        enabled: true,
                        xaxis: {
                            min: this.getLastWeek().getTime(),
                            max: new Date().getTime(),
                        },
                    },
                    legend: {
                        show: false,
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: [...value.categories],
                    },
                    yaxis: {
                        tickAmount: 2,
                    },
                }

                chartID.push(`${value.title}`)
                chartID.push(`${value.title}-subChart`)

                let type = value.type
                if (value.type === 'column') {
                    type = 'bar'
                }
                myChart[chart][value.title] = {
                    series,
                    options,
                    sub_options,
                    type,
                    title,
                }
            })
        })

        this.setState({ chartID, myChart })
    }

    //Load data for region after data is retrieved from DB
    loadData() {
        console.log('Processing data')
        const allCharts = Object.keys(this.state.layout) //get all charts from layout
        let data = this.props.chart.data
        let chartData = {}

        allCharts.map(chart => {
            let { metric, scope, timeRange, y, chartType } = this.state.layout[
                chart
            ]
            let values = []
            if (this.state.layout[chart].type === 'text') {
                for (let i = 0; i < metric.length; i++) {
                    let value = this.processSummaryData(
                        data,
                        metric[i],
                        scope[i],
                        timeRange[i],
                        y[i]
                    )
                    value.type = chartType[i]
                    values.push(value)
                }
            } else {
                for (let i = 0; i < metric.length; i++) {
                    let value = this.processData(
                        data,
                        metric[i],
                        scope[i],
                        timeRange[i],
                        y[i]
                    )
                    value.type = chartType[i]
                    values.push(value)
                }
            }
            chartData[chart] = values
            console.log('renderText values:', values)
        })
        console.log('chartData:', chartData)
        return chartData
        //this.setState({ chartData })
    }

    handleSubmit = async event => {
        event.preventDefault()
        try {
            console.log('calling props.loadData')
            await this.props.chart.loadData()
            // if (this.props.dataLoaded) {
            //     this.initData()
            //     this.setState({ dataLoaded: true })
            // }
        } catch (e) {
            alert(e)
        }
    }

    processSummaryData(inData, metric, scope, timeRange, ys) {
        let d = [...inData[metric][scope][timeRange][0].data]

        let data = []
        let categories = []

        console.log('ProcessSummaryData', d)
        for (let i = 0; i < d.length; i++) {
            categories[i] = d[i].x
            data[i] = {}
            for (let j = 0; j < ys.length; j++) {
                let y = ys[j]
                data[i][y] = d[i][y]
                console.log('data', data)
            }
        }
        return {
            title: `${metric}`,
            name: timeRange,
            data,
            categories,
        }
    }

    getLastWeek() {
        var today = new Date()
        var lastWeek = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() - 7
        )
        return lastWeek
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>AutoSender</h1>
                <p>AutoSender Management Analytics System</p>
                <div>
                    <Link to="/login" className="btn btn-info btn-lg">
                        Login
                    </Link>
                    <Link to="/signup" className="btn btn-success btn-lg">
                        Signup
                    </Link>
                </div>
            </div>
        )
    }
    processData(inData, metric, scope, timeRange, y) {
        let dd = [...inData[metric][scope][timeRange]]
        let series = []

        let categories = []
        for (let j = 0; j < dd.length; j++) {
            let serie = {}
            let ddd = dd[j]
            let d = [...ddd.data]
            let data = []

            serie.name = ddd.name
            for (let i = 0; i < d.length; i++) {
                categories[i] = d[i].x
                data[i] = { x: d[i].x, y: d[i][y] }
            }
            serie.data = data
            series.push(serie)
        }
        console.log(series)
        return {
            title: `${metric} ${y} by ${timeRange}`,
            name: timeRange,
            series,
            categories,
        }
    }

    renderChart(ch) {
        const myChart = Object.keys(this.state.myChart[ch])
        const listChart = myChart.map((m, index) => {
            let chart = this.state.myChart[ch][m]
            console.log('chart:', chart)
            return (
                <Well key={`${m} - ${index}`}>
                    <Row key={`row-${index}`}>
                        <h3>{chart.title}</h3>
                        <Chart
                            options={chart.options}
                            series={chart.series}
                            type={chart.type}
                            width={'100%'}
                            height={'400px'}
                        />
                        {/* <Chart
                            options={chart.sub_options}
                            series={chart.series}
                            type={chart.type}
                            width={'100%'}
                            height={'100px'}
                        /> */}
                    </Row>
                </Well>
            )
        })
        console.log('listChart', listChart)
        return <div key="region-chart"> {listChart} </div>
    }

    renderData() {
        if (!this.state.dataLoaded) {
            return (
                <div className="lander">
                    <Well>
                        <form onSubmit={this.handleSubmit}>
                            <LoaderButton
                                block
                                bsSize="large"
                                bsStyle="success"
                                type="submit"
                                isLoading={this.props.isLoading}
                                text="Load Data"
                                loadingText="Data loading…"
                            />
                        </form>
                    </Well>
                </div>
            )
        }
        const allCharts = Object.keys(this.state.layout) //get all charts from layout

        return (
            <Grid className="autosender_region_grid">
                {allCharts.map(chart => {
                    return this.renderChart(chart)
                })}
            </Grid>
        )
    }

    render() {
        return (
            <div className="Region">
                {this.props.isAuthenticated
                    ? this.renderData()
                    : this.renderLander()}
            </div>
        )
    }
}
