//@ts-check
import React, { Component, Fragment } from 'react'
import { Auth } from 'aws-amplify'
import { Link, withRouter } from 'react-router-dom'
import { Nav, Navbar, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Routes from './Routes'
import './App.css'
import { API } from 'aws-amplify'
import * as merge from 'deepmerge'

const processDate = () => {
    const date = new Date()

    const split = {
        Date:
            date.getUTCDate() >= 10
                ? date.getUTCDate()
                : '0' + date.getUTCDate(),
        Hour:
            date.getUTCHours() >= 10
                ? date.getUTCHours()
                : '0' + date.getUTCHours(),
        Minute:
            date.getUTCMinutes() >= 10
                ? date.getUTCMinutes()
                : '0' + date.getUTCMinutes(),
        Month:
            date.getUTCMonth() >= 9
                ? date.getUTCMonth() + 1
                : '0' + (date.getUTCMonth() + 1),
        Year: date.getUTCFullYear(),
    }
    return split
}

const emptyTarget = value => (Array.isArray(value) ? [] : {})
const clone = (value, options) => merge(emptyTarget(value), value, options)

function combineMerge(target, source, options) {
    const destination = target.slice()
    console.log('target and source', { target, source })

    source.forEach(function(e, i) {
        if (typeof destination[i] === 'undefined') {
            console.log('undefined', e, i)
            const cloneRequested = options.clone !== false
            const shouldClone = cloneRequested && options.isMergeableObject(e)
            destination[i] = shouldClone ? clone(e, options) : e
        } else if (options.isMergeableObject(e)) {
            destination[i] = merge(target[i], e, options)

            console.log(e, i, options)
        } else if (target.indexOf(e) === -1) {
            console.log('-1', e, i)
            destination.push(e)
        } else if (
            target[0].hasOwnProperty('x') &&
            e.hasOwnProerty('x') &&
            target[0].x === e.x
        ) {
            destination[i] = target[0]
            console.log('Found Data!', target[0], destination)
        }
    })
    return destination
}

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            dataLoaded: false,
            isLoading: false,
            //Below are chart related data
            chart: {
                loadData: this.retriveData,
                refreshData: this.reloadData,
                homeProcessed: false,
                regionProcessed: false,
                localeProcessed: false,
                filter: {
                    metric: '', // metric: ['registration', 'transaction', 'referral', 'tasks', 'sms'],
                    timeRange: '', // month or last week
                    type: '', // count or value
                },
                products: {
                    'com.freeramble.smsautosender.1': 0.99,
                    'com.freeramble.smsautosender.2': 1.99,
                    'com.freeramble.smsautosender.3': 4.99,
                    'com.freeramble.smsautosender.4': 9.99,
                },
                metrics: [
                    'registration',
                    'transaction',
                    'referral',
                    'tasks',
                    'sms',
                ],
                scopes: ['TOTAL', 'REGION', 'LOCALE'],
                timeRange: ['YEAR', 'DATE', 'REGION', 'LOCALE'],
                data: {}, // will be filled with data with above data
                chartData: {},
            },
        }
    }

    processSummaryData(inData, metric, scope, timeRange, ys) {
        let d = [...inData[metric][scope][timeRange][0].data]

        let data = []
        let categories = []

        console.log('ProcessSummaryData', d)
        for (let i = 0; i < d.length; i++) {
            categories[i] = d[i].x
            data[i] = {}
            for (let j = 0; j < ys.length; j++) {
                let y = ys[j]
                data[i][y] = d[i][y]
                console.log('data', data)
            }
        }
        return {
            title: `${metric}`,
            name: timeRange,
            data,
            categories,
        }
    }
    fineTuneData(inData, metric, scope, timeRange, y) {
        let d = [...inData[metric][scope][timeRange][0].data]

        let data = []
        let categories = []
        if (timeRange !== 'DATE') {
            d = d.sort((a, b) => b[y] - a[y])
        }

        for (let i = 0; i < d.length; i++) {
            categories[i] = d[i].x
            data[i] = { x: d[i].x, y: d[i][y] }
        }
        return {
            title: `${metric} ${y} by ${timeRange}`,
            name: timeRange,
            data,
            categories,
        }
    }
    fineTuneData1(inData, metric, scope, timeRange, y) {
        let dd = [...inData[metric][scope][timeRange]]
        let series = []

        let categories = []
        for (let j = 0; j < dd.length; j++) {
            let serie = {}
            let ddd = dd[j]
            let d = [...ddd.data]
            let data = []

            serie.name = ddd.name
            for (let i = 0; i < d.length; i++) {
                categories[i] = d[i].x
                data[i] = { x: d[i].x, y: d[i][y] }
            }
            serie.data = data
            series.push(serie)
        }
        console.log(series)
        return {
            title: `${metric} ${y} by ${timeRange}`,
            name: timeRange,
            series,
            categories,
        }
    }

    retriveData = async () => {
        console.log('retriveData', this.state)
        this.setState({ dataLoaded: false })
        this.setState({ isLoading: true })
        await this.loadData()
        //await this.getSeriesData()
        this.setState({ dataLoaded: true })
        this.setState({ isLoading: false })
    }

    async getSeriesData() {
        const metric = 'registration'
        const range = 'DATE'
        const result = await this.getData(metric, 'REGION', range)
        const r = this.processSeriesData(result.data)
        console.log('Series data processed:', r)
        //return result;
        console.log('Series Data', result)
    }

    processSeriesData = d => {
        console.log('insde processSeries data:', d)
        if (d && d.length === 0) {
            return null
        }
        let date = d.map(m => {
            return m.date.substring(
                m.date.indexOf('#') + 1,
                m.date.lastIndexOf('#')
            )
        })

        let series = d.map(m => {
            return m.date.substring(m.date.lastIndexOf('#') + 1, m.date.length)
        })

        date = [...new Set(date)]
        series = [...new Set(series)]

        console.log(date, series)
        let result = []

        series.forEach(s => {
            let serie = {}
            let dd = []

            if (s === '') {
                serie.name = 'TOTAL'
            } else {
                serie.name = s
            }

            date.forEach(c => {
                //iterate each date
                let data = {}
                let ff = d
                    .filter(
                        //match the date
                        f =>
                            f.date.substring(
                                f.date.indexOf('#') + 1,
                                f.date.lastIndexOf('#')
                            ) === c
                    )
                    .filter(
                        //match the series (REGION/LOCALE)
                        m =>
                            m.date.substring(
                                m.date.lastIndexOf('#') + 1,
                                m.date.length
                            ) === s
                    )

                if (ff.length === 1) {
                    let keys = Object.keys(ff[0].KPI)
                    let value = 0
                    let count = 0
                    keys.map(m => {
                        value += ff[0].KPI[m].value
                        count += ff[0].KPI[m].count
                    })
                    data = {
                        x: c,
                        count: count,
                        value: value,
                        kpi: ff[0].KPI,
                    }
                } else {
                    data = {
                        x: c,
                        count: null,
                        value: null,
                        kpi: null,
                    }
                }
                dd.push(data)
            })
            serie.data = dd
            console.log('serie', serie)
            result.push(serie)
        })
        return result
    }

    reloadData = async () => {
        this.setState({ isLoading: true })
        await this.refreshData()
        //await this.getSeriesData()
        this.setState({ isLoading: false })
    }
    refreshData = async () => {
        let metrics = this.state.chart.metrics
        let scopes = this.state.chart.scopes

        const tempDate = processDate()
        const utcYear = tempDate.Year
        const utcMonth = tempDate.Month
        const utcDate = tempDate.Date

        let timeRange = `DATE#${utcYear}-${utcMonth}-${utcDate}`

        const oldData = { ...this.state.chart.data }
        console.log('oldData', oldData)
        let data = {}
        for (let j = 0; j < metrics.length; j++) {
            let metric = metrics[j]
            data[metric] = {}

            for (let k = 0; k < scopes.length; k++) {
                let scope = scopes[k]
                const results = await this.getData(metric, scope, timeRange)

                data[metric][scope] = {}

                const d = this.processTimeData(results.data)
                data[metric][scope]['DATE'] = d
                let t = oldData[metric][scope]['DATE']
                if (d) {
                    d.forEach((e, i) => {
                        t.forEach(element => {
                            if (element.name === e.name) {
                                const index = element.data.findIndex(
                                    dd => dd.x === e.data[0].x
                                )
                                if (index) {
                                    element.data[index] = e.data[0]
                                    console.log('Found', index, element, e)
                                }
                            }
                        })
                    })
                }
            }
        }
        console.log('New Data before merge:', data)
        console.log('Old Data', oldData)

        const chart = {
            ...this.state.chart,
            data: oldData,
        }
        this.setState({ chart })
        console.log('Data refreshed: ', this.state.chart)
    }

    loadData = async () => {
        let metrics = this.state.chart.metrics
        let scopes = this.state.chart.scopes
        let timeRange = this.state.chart.timeRange

        let data = {}
        for (let j = 0; j < metrics.length; j++) {
            let metric = metrics[j]
            data[metric] = {}

            for (let k = 0; k < scopes.length; k++) {
                let scope = scopes[k]
                let ps = timeRange.map(range => {
                    return this.getData(metric, scope, range)
                })
                const results = await Promise.all(ps)

                console.log('Raw data', results)
                data[metric][scope] = {}

                for (let i = 0; i < results.length; i++) {
                    const d = this.processTimeData(results[i].data)
                    data[metric][scope][timeRange[i]] = d
                }
            }
        }

        const chart = {
            ...this.state.chart,
            data: data,
        }
        this.setState({ chart })
        console.log('Data loaded: ', this.state.chart)
    }

    //Get data from API
    getData = (metric, scope, timeRange) => {
        const options = {
            body: {
                metric: metric,
                scope: scope,
                timeRange: timeRange,
            },
        }
        return API.post('autosender', '/data', options)
        //return data.data
    }

    processTimeData = d => {
        if (d && d.length === 0) {
            return null
        }
        let date = d.map(m => {
            return m.date.substring(
                m.date.indexOf('#') + 1,
                m.date.lastIndexOf('#')
            )
        })

        let series = d.map(m => {
            return m.date.substring(m.date.lastIndexOf('#') + 1, m.date.length)
        })

        date = [...new Set(date)]
        series = [...new Set(series)]

        let result = []

        series.forEach(s => {
            let serie = {}
            let dd = []

            if (s === '') {
                serie.name = 'TOTAL'
            } else {
                serie.name = s
            }

            date.forEach(c => {
                //iterate each date
                let data = {}
                let ff = d
                    .filter(
                        //match the date
                        f =>
                            f.date.substring(
                                f.date.indexOf('#') + 1,
                                f.date.lastIndexOf('#')
                            ) === c
                    )
                    .filter(
                        //match the series (REGION/LOCALE)
                        m =>
                            m.date.substring(
                                m.date.lastIndexOf('#') + 1,
                                m.date.length
                            ) === s
                    )

                if (ff.length === 1) {
                    let keys = Object.keys(ff[0].KPI)
                    let value = 0
                    let count = 0
                    let sales = 0
                    keys.map(m => {
                        if (this.state.chart.products.hasOwnProperty(m)) {
                            sales =
                                sales +
                                ff[0].KPI[m].count *
                                    this.state.chart.products[m]
                        }
                        value += ff[0].KPI[m].value
                        count += ff[0].KPI[m].count
                    })
                    data = {
                        x: c,
                        count: count,
                        value: value,
                        sales: sales,
                        kpi: ff[0].KPI,
                    }
                    // if (ff[0].dimension.includes('transaction')) {
                    //     data.sales = 0
                    // }
                } else {
                    data = {
                        x: c,
                        count: null,
                        value: null,
                        sales: null,
                        kpi: null,
                    }
                }
                dd.push(data)
            })
            serie.data = dd
            //console.log('serie', serie)
            result.push(serie)
        })
        return result
    }

    async componentDidMount() {
        try {
            await Auth.currentSession()
            this.userHasAuthenticated(true)
        } catch (e) {
            if (e !== 'No current user') {
                alert(e)
            }
        }
        this.setState({ isAuthenticating: false })
    }

    userHasAuthenticated = authenticated => {
        this.setState({ isAuthenticated: authenticated })
    }

    handleLogout = async event => {
        await Auth.signOut()

        this.userHasAuthenticated(false)

        this.props.history.push('/login')
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            dataLoaded: this.state.dataLoaded,
            isLoading: this.state.isLoading,
            chart: this.state.chart,
        }

        return (
            !this.state.isAuthenticating && (
                <div className="App container">
                    <Navbar fluid collapseOnSelect>
                        <Navbar.Header>
                            <Navbar.Brand>
                                <Link to="/">Dashboard</Link>
                            </Navbar.Brand>
                            <Navbar.Toggle />
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <Nav pullRight>
                                {this.state.isAuthenticated ? (
                                    <Fragment>
                                        <LinkContainer to="/region">
                                            <NavItem>Region</NavItem>
                                        </LinkContainer>
                                        <NavItem onClick={this.handleLogout}>
                                            Logout
                                        </NavItem>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <LinkContainer to="/signup">
                                            <NavItem>Signup</NavItem>
                                        </LinkContainer>
                                        <LinkContainer to="/login">
                                            <NavItem>Login</NavItem>
                                        </LinkContainer>
                                    </Fragment>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>

                    <Routes childProps={childProps} />
                </div>
            )
        )
    }
}

export default withRouter(App)
